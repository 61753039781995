.gallery {
    -webkit-column-gap: 0;
       -moz-column-gap: 0;
            column-gap: 0;
}
@media (min-width: 480px) {
    .gallery {
        -webkit-column-count: 2;
            -moz-column-count: 2;
                column-count: 2;
    }
}
@media (min-width: 1260px) {
    .gallery {
        -webkit-column-count: 3;
            -moz-column-count: 3;
                column-count: 3;
    }
}
  
.gallery-image {
    position: relative;
    margin: 0;
    padding: 0;
}
.gallery-image:before, .gallery-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 16px solid rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    will-change: border;
}
.gallery-image:after {
    border-width: 0;
}

.gallery-image img {
    display: block;
    max-width: 100%;
    height: auto;
}

.back, .titulo-menor {
    color: #212529;
    font-weight: 300;
    font-size: 1.9em;
    
}

.p50 {
    width: 75%;
    margin: 0 auto;
    font-size: 1.1em;
    font-weight: 300;;
}

.ficha-tecnica {
    text-transform: uppercase;
    padding-top: 30px;;
}

a, a:hover {
    color: #212529;
    text-decoration: none;
}

.btn {
    border: 2px solid black;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
  }
  
.custom, .custom:focus {
    border-color: #212529;
    color: #212529;
    box-shadow: none;
}

.sem-margin-bottom {
    margin-bottom: 0px;
}