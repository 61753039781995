.jumbotron {
    background-color: #fff;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron-heading > h1 {
    font-size: 2.5rem;
}

hr {
    background: linear-gradient(to right, #2b2870, #ec286a, #0759fd);
    height: 5px;
    margin-left: 25%;
    margin-right: 25%;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 1.5rem !important;
    }
}