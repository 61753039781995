.personsSection {
    color: #6c757d;
    font-size: 1.25rem;
    font-weight: 300;
}

.profile-cards {
    margin-top: 20px;
}

.profile-cards .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}

.profile-cards .card-img-block img{
    border-radius:5px;
}

.profile-card-1 .card-img-block img {
    box-shadow:0 0 10px #2b2870;
}

.profile-card-2 .card-img-block img {
    box-shadow:0 0 10px #0759fd;
}

.profile-card-3 .card-img-block img {
    box-shadow:0 0 10px #ec286a;
}

@media only screen and (max-width: 768px) {
    .profile-cards {
        margin-top: 30px;
    }

    .personsSection, .card-title {
        font-size: 1.0rem;
    }

    .profile-cards .card-img-block {
        top: -15px;
    }

    .cropped {
        height: 285px;
        padding: 10px;
        border-radius: 2%;
        overflow: hidden;
    }
}