.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.text-muted {
    color: #6c757d!important;
    margin-left: 10%;
    margin-right: 10%;
}

@media only screen and (max-width: 768px) {
    .aboutSection hr {
        height: 3px;
        margin-left: 10%;
        margin-right: 10%;
    }

    .lead {
        font-size: 1.0rem;
    }
}