.view {
    background: url(../../img/bg.jpg)no-repeat center center;
    background-size: cover;
}

.navbar {
    transition:400ms ease;
    background-color: transparent;
    box-shadow: 0 7px 7px 0px rgba(0,0,0,.2);
    font-weight: 300;
}

.scrolled{
    background: rgb(255 255 255 / 75%);
    /* box-shadow: 0 7px 7px 0px linear-gradient(to left, #2b2870, #ec286a, #0759fd); */
}

.no-white-header-font {
    color: #565656 !important;
}

.nav-link-scroll-color {
    color: #565656 !important;
}

.top-nav-collapse {
    background-color: #4285F4;
}

.navbar-brand {
    margin-right: 6rem !important;
}

.nav-item {
    text-transform: uppercase;
}

.nav-logo {
    height: 35px;
}

.centerLogo {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    height: 20%;
}

@media only screen and (max-width: 768px) {
    .navbar-brand {
        margin-right: 0rem !important;
    }
}