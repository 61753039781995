.jumbotron-reduce {
    padding-bottom: 0rem;
}

.svg-container {
    height: 20%;
}

@media only screen and (max-width: 450px) {
    .service-header {
        font-size: 1.20rem;
    }
}